<template>
  <div
    :class="[
      'lp-wrapper',
      { 'overflow-hidden': isOpen, 'system-alert': isSystemAlertEnabled }
    ]"
  >
    <Header @navToggle="onNavToggle" />
    <div class="lp__main-wrapper" :style="computedStyle">
      <slot></slot>
    </div>
    <Footer />
    <Modals />
    <a class="top-link" role="button" v-show="showToTop" @click="toTop">
      <img
        src="@/assets/images/landing/icons/scroll-top-blue.svg"
        alt="scroll"
        width="auto"
        height="auto"
      />
    </a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./LandingPageLayout/Header.vue";
import Footer from "./LandingPageLayout/Footer.vue";
import Modals from "@/components/Shared/Modals.vue";
export default {
  name: "LandingPageLayout",
  components: {
    Header,
    Footer,
    Modals
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.setMarginTop);
    this.setMarginTop();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.setMarginTop);
  },
  data() {
    return {
      showToTop: false,
      isOpen: false,
      marginTop: 64
    };
  },
  computed: {
    ...mapGetters(["isSystemAlertEnabled", "language"]),
    isAuthenticated() {
      return this.$keycloak.authenticated;
    },
    computedStyle() {
      if (this.$route.name === "Courses" && !this.isAuthenticated) {
        return {
          "padding-top": `${this.marginTop}px`,
          "margin-top": "0px"
        };
      } else {
        return {
          "margin-top": `${this.marginTop}px`
        };
      }
    }
  },
  watch: {
    data(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setMarginTop();
      }
    },
    language(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setMarginTop();
      }
    },
    isSystemAlertEnabled() {
      setTimeout(() => {
        this.setMarginTop();
      }, 0);
    }
  },
  methods: {
    toTop() {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
    handleScroll(e) {
      if (e.target.scrollingElement.scrollTop > 500) {
        this.showToTop = true;
      } else {
        this.showToTop = false;
      }
    },
    onNavToggle(t) {
      this.isOpen = t;
    },
    setMarginTop() {
      let headerTotalHeight, navbarHeight;
      headerTotalHeight = document.getElementById("lp-header").offsetHeight;
      navbarHeight = document.getElementById("landing-header").offsetHeight;
      if (
        this.$route.name === "Home" ||
        this.$route.name === "discovery" ||
        this.$route.name === "AccessibilityStatement" ||
        (this.$route.name === "Courses Detail" && !this.isAuthenticated)
      ) {
        if (this.isSystemAlertEnabled) {
          this.marginTop = headerTotalHeight - navbarHeight;
        } else {
          this.marginTop = 0;
        }
      } else {
        this.marginTop = headerTotalHeight;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.lp-wrapper {
  background: #fff;
  position: relative;
  font-family: $font-family-source-sans;
  height: 100%;
  overflow-x: clip;
  .lp__main-wrapper {
    margin-top: 64px;
  }
  .top-link {
    transition: all 0.25s ease-in-out;
    position: fixed;
    bottom: 3em;
    right: 3em;
    display: inline-flex;

    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 100;

    &.show {
      visibility: visible;
      opacity: 1;
    }

    &.hide {
      visibility: hidden;
      opacity: 0;
    }
  }
}
// rtl layout
[dir="rtl"] {
  .lp-wrapper {
    .top-link {
      right: auto;
      left: 3em;
    }
  }
}
</style>

<style lang="scss" scoped>
// common media styles for landing page
@media screen and (max-width: 768px) {
  .lp-wrapper {
    h5 {
      font-size: 48px !important;
    }
    h6 {
      font-size: 34px !important;
      line-height: 45px !important;
      letter-spacing: -0.59px !important;
    }
    .top-link {
      margin: 0 5px 10px 0;
      right: 1em !important;
    }
  }
  .app-hy {
    .lp-wrapper {
      h5 {
        font-size: 38px !important;
      }
    }
  }
  @media screen and (max-width: 990px) {
    .lp-wrapper {
      .top-link {
        bottom: 8rem;
      }
    }
  }
}
</style>
